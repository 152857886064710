import React from "react";
import { isUndefined, startCase } from "lodash";
import { useHistory } from "react-router";
import { pluralize } from "common/utils/strings";
import {
  CertificateUpload,
  CertificateUploadStatus,
  DocumentUpload,
  useGetSubmissionDocumentUploadsQuery,
  useUpdateSubmissionVisibilityMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { CommonModal } from "../../Common/CommonModal";
import { DynamicOverflowWrapper } from "../../Common/DynamicOverflowWrapper";
import { Body } from "../../Common/Typography";
import { OverflowText } from "../../Submissions/Fields/__styles__/DocumentUploadField";
import { ViewDocumentLink } from "../DocumentUploads/ViewDocumentLink";
import { DocumentList, DocumentListItem } from "./__styles__/EditVisibility";

export type EditVisibilityProps = {
  submission: {
    id: string;
    hiddenFromPublic: boolean;
    property?: Maybe<{
      id: string;
    }>;
  };
  submissionTypeName: string;
  prevLocation?: string;
  closeModal: () => void;
  onUpdate?: () => void;
};

const EditVisibility = ({
  submission,
  submissionTypeName,
  prevLocation,
  closeModal,
  onUpdate,
}: EditVisibilityProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const history = useHistory();

  const {
    data: submissionDocumentUploadsData,
    loading: submissionDocumentUploadsLoading,
  } = useGetSubmissionDocumentUploadsQuery({
    variables: { submissionId: submission.id },
    fetchPolicy: "network-only",
  });

  const filteredDocumentUploads =
    submissionDocumentUploadsData?.submission?.documentUploads
      ?.filter(
        documentUpload =>
          documentUpload.hiddenFromPublic === submission.hiddenFromPublic
      )
      .sort(({ originalFilename: a }, { originalFilename: b }) => {
        return a > b ? 1 : -1;
      });

  const [updateSubmissionVisibility, { loading }] =
    useUpdateSubmissionVisibilityMutation({
      variables: {
        data: {
          submissionId: submission.id,
          hiddenFromPublic: !submission.hiddenFromPublic,
        },
      },
      onCompleted: data => {
        addSuccessToast(
          `Your submission was successfully ${
            data.updateSubmissionVisibility.hiddenFromPublic
              ? "hidden"
              : "shown"
          }`
        );
        onUpdate?.();
        if (!isUndefined(prevLocation)) {
          history.push(prevLocation);
        }
        closeModal();
      },
      onError: () => {
        closeModal();
        addErrorToast(
          "There was an issue updating visibility. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      },
    });

  const verb = submission.hiddenFromPublic ? "show" : "hide";
  const adjective = submission.hiddenFromPublic ? "visible" : "hidden";
  const title = `${startCase(verb)} on public website`;
  const filePlural = filteredDocumentUploads?.length
    ? pluralize({
        count: filteredDocumentUploads.length,
        options: { singular: "file", plural: "files" },
      })
    : null;

  const primaryButtonText = filePlural
    ? `${startCase(verb)} record and ${filePlural}`
    : `${startCase(verb)} record`;

  return (
    <CommonModal
      title={title}
      subtitle={submissionTypeName}
      content={
        <EditVisibilityModalContent
          submissionDocumentUploadsLoading={submissionDocumentUploadsLoading}
          filteredDocumentUploads={filteredDocumentUploads}
          verb={verb}
          filePlural={filePlural}
          adjective={adjective}
        />
      }
      onCancel={closeModal}
      onSubmit={updateSubmissionVisibility}
      primaryButtonText={primaryButtonText}
      loading={loading || submissionDocumentUploadsLoading}
    />
  );
};

type EditVisibilityModalContentProps = {
  submissionDocumentUploadsLoading: boolean;
  filteredDocumentUploads?: Array<
    Pick<DocumentUpload, "id" | "originalFilename" | "hiddenFromPublic"> & {
      certificateUpload?: Maybe<Pick<CertificateUpload, "status">>;
    }
  >;
  verb: string;
  filePlural: Maybe<string>;
  adjective: string;
};

const EditVisibilityModalContent = ({
  submissionDocumentUploadsLoading,
  filteredDocumentUploads,
  verb,
  filePlural,
  adjective,
}: EditVisibilityModalContentProps) => {
  if (submissionDocumentUploadsLoading) {
    return null;
  }
  return (
    <div>
      <Body size="default" type="regular">
        {`Are you sure you want to ${verb} this record on the public website?`}
      </Body>
      {filePlural && (
        <Body size="default" type="regular">
          {` The following attached ${filePlural} will also be ${adjective}:`}
        </Body>
      )}

      {!!filteredDocumentUploads?.length && (
        <DocumentList>
          {filteredDocumentUploads.map(documentUpload => (
            <DocumentListItem>
              <DocumentListItemContent documentUpload={documentUpload} />
            </DocumentListItem>
          ))}
        </DocumentList>
      )}
    </div>
  );
};

type DocumentListItemContentProps = {
  documentUpload: Pick<
    DocumentUpload,
    "id" | "originalFilename" | "hiddenFromPublic"
  > & {
    certificateUpload?: Maybe<Pick<CertificateUpload, "status">>;
  };
};
const DocumentListItemContent = ({
  documentUpload,
}: DocumentListItemContentProps) => {
  if (
    documentUpload.id &&
    documentUpload.certificateUpload?.status !==
      CertificateUploadStatus.PROCESSING
  ) {
    return (
      <DynamicOverflowWrapper
        Link={ViewDocumentLink}
        linkProps={{
          documentUploadId: documentUpload.id,
          isDocumentUploadField: true,
        }}
        text={documentUpload.originalFilename}
        key={documentUpload.id}
      />
    );
  }
  return <OverflowText>{documentUpload.originalFilename}</OverflowText>;
};

export default EditVisibility;
