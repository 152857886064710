import React, { useContext, useState } from "react";
import * as Sentry from "@sentry/browser";
import { isNull, isUndefined } from "lodash";
import { PDFCheckBox, PDFDocument } from "pdf-lib";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { MIME_TYPE, OBJECT_TYPE } from "common/constants";
import { getPath } from "common/routing";
import {
  allFontNames,
  findFieldFont,
  Font,
  getFieldType,
  visitPDFFields,
} from "common/utils/documentTemplates";
import { Attachments, isAttachableToMap } from "common/utils/objectAttachments";
import { isNotNull } from "common/utils/tools";
import {
  GetCustomMapsForTemplatesQuery,
  useGetCustomMapsForTemplatesQuery,
  useGetPresignedDocumentTemplateUploadS3UrlLazyQuery,
} from "../../../../generated/graphql";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  ContentSection,
  FormSection,
  InputRow,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import FullPageFormLayout from "../../../Common/FullPageFormLayout";
import { HeaderRowComponent } from "../../../FileUploads";
import {
  Select,
  SingleFileUpload,
  Text,
} from "../../../Inputs/react-hook-form";
import { ButtonContainer, Container } from "./__styles__/DocumentTemplates";
import { CreateDocumentTemplateForm, FORM_STAGES } from "./CreateOrUpdateForm";

export type UploadStepProps = {
  setFormStage: (formStage: FORM_STAGES) => void;
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
  get?: typeof fetch;
};

const UploadStep = ({
  setFormStage,
  useDropzone = undefined,
  get = fetch,
}: UploadStepProps) => {
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const location = useLocation<undefined | { prevLocation?: string }>();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [selectedCustomMap, setSelectedCustomMap] =
    useState<
      Maybe<
        NonNullable<
          GetCustomMapsForTemplatesQuery["account"]
        >["customMaps"][number]
      >
    >();
  const [documentTemplateUploadIsValid, setDocumentTemplateUploadIsValid] =
    useState(false);
  const { addErrorToast } = useStatusToasts();

  const { data, loading: loadingCustomMaps } =
    useGetCustomMapsForTemplatesQuery();

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useFormContext<CreateDocumentTemplateForm>();

  const allCustomMaps = data?.account?.customMaps ?? [];

  const objectTypeOptions = [
    {
      label: "Properties",
      value: null,
    },
    ...allCustomMaps
      .filter(customMap => customMap.isObject)
      .map(customMap => ({
        label: customMap.name,
        value: customMap.id,
      })),
  ];

  const formCustomMapId = watch("customMapId");

  const accountDocumentTypes =
    account?.accountDocumentTypes
      .filter(adt => {
        // If no object type has been selected, don't populate the ADType list. If properties is selected, the value
        // will be null.
        if (isUndefined(formCustomMapId)) {
          return false;
        }

        if (!adt.allowedMimeTypes.includes(MIME_TYPE.PDF)) {
          return false;
        }

        if (selectedCustomMap) {
          return isAttachableToMap({
            attachments: adt.attachments as Attachments,
            customMapId: selectedCustomMap.id,
          });
        }

        return adt.attachments.some(
          attachment => attachment.type === OBJECT_TYPE.PROPERTY
        );
      })
      .map(adt => ({
        label: adt.name,
        value: adt.id,
      })) ?? [];

  const prevLocation =
    location.state?.prevLocation ?? getPath("documentTemplates");

  const setMappingPdfFields = async (pdfBlob: Blob) => {
    const pdfBuffer = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const pdfForm = pdfDoc.getForm();
    const mappingWithPdfFields = pdfForm
      .getFields()
      .filter(field => !field.getName().includes("ignore"))
      .map(field => ({
        label: field.getName(),
        value: null,
        pdfFieldType: getFieldType(field),
        forerunnerFieldType: null,
      }));

    setValue("mapping", mappingWithPdfFields);
  };

  const upload = async ({
    uploadURL,
    pdfBlob,
  }: {
    uploadURL: string;
    pdfBlob: Blob;
  }) => {
    setUploadingFile(true);

    try {
      const result = await get(uploadURL, {
        method: "PUT",
        body: pdfBlob,
      });

      if (!result.ok) {
        throw new Error(
          `Received a ${result.status} when uploading document template`
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      addErrorToast(
        "Your upload failed. Please remove the file and try reuploading it."
      );
    } finally {
      setUploadingFile(false);
    }
  };

  const pdfBlob = watch("documentTemplateUpload.blob");

  const [getPresignedUrl, { loading }] =
    useGetPresignedDocumentTemplateUploadS3UrlLazyQuery({
      onCompleted: async data => {
        const { s3Key, uploadURL } =
          data.getPresignedDocumentTemplateUploadS3Url;

        setValue("s3Key", s3Key);
        await setMappingPdfFields(pdfBlob);
        await upload({ uploadURL, pdfBlob });

        setFormStage(FORM_STAGES.MAPPING);
      },
      fetchPolicy: "network-only",
    });

  const disabled = loading || uploadingFile || !documentTemplateUploadIsValid;

  const rightContainer = (
    <ButtonContainer>
      <Button
        styleVariant="secondary"
        onClick={() => history.push({ pathname: prevLocation })}
        size="medium"
      >
        Cancel
      </Button>
      <Button
        styleVariant="primary"
        disabled={disabled}
        loading={uploadingFile || loading}
        onClick={handleSubmit(() => getPresignedUrl())}
        size="medium"
      >
        Next
      </Button>
    </ButtonContainer>
  );

  return (
    <FullPageFormLayout
      subtitle="Import template file"
      prevLocation={prevLocation}
      rightContainer={rightContainer}
    >
      <Container>
        <FormSection>
          <ContentSection overflows={true}>
            <InputRow>
              <Text
                label="Name"
                size="medium"
                required
                {...register("name", {
                  required: "Name is required",
                })}
                error={errors.name?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Object type"
                name="customMapId"
                placeholder="Select..."
                size="medium"
                control={control}
                required
                disabled={loadingCustomMaps}
                options={objectTypeOptions}
                onChange={value => {
                  resetField("associatedDocumentTypeId");
                  if (isNull(value)) {
                    setSelectedCustomMap(null);
                  } else {
                    setSelectedCustomMap(
                      allCustomMaps.find(customMap => customMap.id === value)!
                    );
                  }
                }}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Associated document type"
                name="associatedDocumentTypeId"
                placeholder="Select..."
                size="medium"
                control={control}
                rules={{
                  required: "Document type is required",
                }}
                required
                disabled={isUndefined(formCustomMapId)}
                options={accountDocumentTypes}
                error={errors.associatedDocumentTypeId?.message}
              />
            </InputRow>
            <SingleFileUpload
              label="Upload file"
              name="documentTemplateUpload"
              description="File must be a PDF file with smart fields"
              control={control}
              rules={{
                required: "PDF file is required",
                validate: async file => {
                  const document = await PDFDocument.load(
                    await file.blob.arrayBuffer()
                  )!;
                  const warnings = visitPDFFields({
                    document,
                    visit: ({ field, fieldName }) => {
                      if (field instanceof PDFCheckBox) {
                        return null;
                      }
                      const fontName = findFieldFont(field.acroField);
                      if (!allFontNames.includes(fontName as Font)) {
                        return `Font ${fontName} for field ${fieldName} is not a supported font.`;
                      } else {
                        return null;
                      }
                    },
                  }).filter(isNotNull);

                  if (!warnings.length) {
                    setDocumentTemplateUploadIsValid(true);
                    return true;
                  } else {
                    return warnings.join("\n");
                  }
                },
              }}
              required
              useDropzone={useDropzone}
              allowedMimeTypes={[MIME_TYPE.PDF]}
              fileHeader={
                <HeaderRowComponent hasGreenCheckmark={true}>
                  <div tabIndex={0}>File name</div>
                </HeaderRowComponent>
              }
            />
          </ContentSection>
        </FormSection>
      </Container>
    </FullPageFormLayout>
  );
};

export default UploadStep;
