import React, { useContext } from "react";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import { OBJECT_TYPE, SUPPORT_EMAIL } from "common/constants";
import { buildLink } from "common/routing";
import {
  Attachment,
  Attachments,
  isAttachableToMap,
  isPropertyAttachment,
} from "common/utils/objectAttachments";
import {
  CurrentUserQuery,
  useGetAccountDocumentTemplatesForMapQuery,
} from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import { AuthContext } from "../../Authorization/AuthContext";
import { Button } from "../../Common/Button";
import { EmptyState, EmptyStateProps } from "../../Common/EmptyState";
import Modal from "../../Common/Modal";
import { GenerateDocumentForm } from "../../DocumentUploads/GeneratedDocuments/GenerateDocumentForm";
import UploadDocumentsForm from "../../DocumentUploads/UploadDocumentsForm";
import { DropdownMenu } from "../../Inputs";
import { LayerContext } from "../../Maps/layers";
import { Title } from "./__styles__/List";

type ListObject = {
  id: string;
} & (
  | { objectType: OBJECT_TYPE.PROPERTY; objectLabel: string }
  | {
      objectType: OBJECT_TYPE.CUSTOM_MAP_GEOMETRY;
      objectLabel: string;
      customMapId: string;
    }
);

export interface Props {
  object: ListObject;
  children: Array<React.ReactNode>;
}

const List = ({ object, children }: Props) => {
  const { authorized, isGuest, account } = useContext(AuthContext);
  const { updateMap } = useContext(LayerContext);
  const history = useHistory();
  const { t } = useTranslation();

  const canUploadDocument = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "create",
  });

  const canGenerateDocument = authorized({
    resource: RESOURCE_NAME.GENERATED_DOCUMENT,
    permission: "create",
  });

  const canListDocumentTemplates = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "list",
  });

  const { data, loading } = useGetAccountDocumentTemplatesForMapQuery({
    skip: !canListDocumentTemplates,
  });

  const documentTemplates =
    data?.account?.documentTemplates.filter(template =>
      object.objectType === OBJECT_TYPE.PROPERTY
        ? isNil(template.customMapId)
        : object.customMapId === template.customMapId
    ) ?? [];

  const accountDocumentTypeFilter: (
    accountDocumentType: NonNullable<
      CurrentUserQuery["currentUser"]
    >["account"]["accountDocumentTypes"][number]
  ) => boolean = accountDocumentType => {
    if (object.objectType === OBJECT_TYPE.PROPERTY) {
      return accountDocumentType.attachments.some(attachment =>
        isPropertyAttachment(attachment as Attachment)
      );
    } else {
      return isAttachableToMap({
        attachments: accountDocumentType.attachments as Attachments,
        customMapId: object.customMapId,
      });
    }
  };

  const hasAccountDocumentTypes = account?.accountDocumentTypes.filter(
    accountDocumentTypeFilter
  ).length;

  const [showUploadModal, hideUploadModal] = useModal(
    () => (
      <Modal onRequestClose={hideUploadModal}>
        <UploadDocumentsForm
          closeModal={() => {
            hideUploadModal();
          }}
          updateMap={updateMap}
          object={object}
          accountDocumentTypeFilter={accountDocumentTypeFilter}
        />
      </Modal>
    ),
    [object]
  );

  const onClickUpload = () => {
    track("Clicked upload from EC List", {
      propertyId: object.id,
      fullAddress: object.objectLabel,
    });
    showUploadModal();
  };

  const onClickGenerate = () => {
    track("Clicked 'Generate from template' button", {
      propertyId: object.id,
      fullAddress: object.objectLabel,
    });
    showGenerateModal();
  };

  const [showGenerateModal, hideGenerateModal] = useModal(
    () => (
      <Modal onRequestClose={hideGenerateModal}>
        <GenerateDocumentForm
          object={object}
          documentTemplates={documentTemplates}
          updateMap={updateMap}
          closeModal={() => hideGenerateModal()}
        />
      </Modal>
    ),
    [documentTemplates, object]
  );

  const actions = [
    {
      label: "Upload file",
      onClick: onClickUpload,
      disabled: !canUploadDocument || !hasAccountDocumentTypes,
    },
    {
      label: "Generate from template",
      onClick: onClickGenerate,
      disabled: !canGenerateDocument,
    },
  ];

  const AddNewButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <Button
      onClick={onClick}
      styleVariant="primary"
      size="small"
      data-testid="addNewButton"
      rightIconName="chevron-down"
    >
      Add new
    </Button>
  );

  const title = isGuest
    ? t("property-files-empty-state-header")
    : `No files uploaded`;

  let body, primaryButtonProps;
  if (isGuest) {
    body = t("property-files-empty-state-text");
    primaryButtonProps = {
      label: t("get-help"),
      onClick: () => history.push(buildLink("getHelp")),
    };
  } else if (hasAccountDocumentTypes) {
    body = `Add files to get started.`;
  } else {
    body = `This feature has not been configured on your account. Reach out to your customer support representative to learn more.`;
    primaryButtonProps = {
      label: "Contact us",
      onClick: () => window.open(`mailto:${SUPPORT_EMAIL}`),
    };
  }
  const emptyStateProps: EmptyStateProps = {
    icon: "add-file",
    title,
    body,
    primaryButtonProps,
  };

  return (
    <div>
      <Title>
        {t("property-files-header")}
        {!isGuest &&
          !loading &&
          (documentTemplates.length > 0 ? (
            <DropdownMenu actions={actions} customButton={AddNewButton} />
          ) : (
            <Button
              size="small"
              styleVariant="primary"
              onClick={onClickUpload}
              disabled={!canUploadDocument || !hasAccountDocumentTypes}
              data-testid="uploadButton"
              leftIconName="plus"
            >
              Upload
            </Button>
          ))}
      </Title>
      {children.length ? children : <EmptyState {...emptyStateProps} />}
    </div>
  );
};

export default List;
